import { Button, Row, Col, Image, Dropdown, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useMobxStores } from "../../stores/stores";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  BookOutlined,
} from "@ant-design/icons";
import CartDropdownMenu from "./CartDropdownMenu";
import { constants } from "../../consts/Variables";

const MainHeader = (props) => {
  const { userStore, marketplaceStore, errorStore } = useMobxStores();
  const [metamask, setMetamask] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {}, [marketplaceStore.metaMaskIsConnected]);

  useEffect(() => {
    marketplaceStore.setMetaMaskIsConnected(
      localStorage.getItem("metaMask") ? true : false
    );
  }, []);

  function handleLocalStorageChange() {
    marketplaceStore.setMetaMaskIsConnected(
      localStorage.getItem("metaMask") ? true : false
    );
  }
  window.addEventListener("storage", handleLocalStorageChange);

  const handleConnect = async () => {
    if (window.ethereum) {
      await window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((accounts) => {
          setMetamask(accounts[0]);
          localStorage.setItem("metaMask", accounts[0]);
          marketplaceStore.setMetaMaskIsConnected(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      errorStore.setMessage("MetaMask plugin is required!");
      errorStore.setWarning(true);
    }
  };

  const updateWallet = (accounts) => {
    const balance = window.ethereum.request({
      method: "eth_getBalance",
      params: [accounts[0], "latest"],
    });
    const chainId = window.ethereum.request({
      method: "eth_chainId",
    });
    console.log(balance, chainId);
  };

  // const checkMetamaskConnection = async () => {
  //   if (window.ethereum) {
  //     if (window.ethereum.isConnected()) {
  //       await window.ethereum
  //         .request({
  //           method: "eth_requestAccounts",
  //         })
  //         .then((accounts) => {
  //           setMetamask(accounts);
  //           localStorage.setItem("metaMask", accounts);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       setMetamask(null);
  //     }
  //   } else {
  //     console.log("MetaMask is not installed");
  //   }
  // };
  // setInterval(checkMetamaskConnection, 5000);

  const onMenuItemSelect = ({ keyPath }) => {
    keyPath[0] === "/community"
      ? (window.location = constants.FORUM_URL)
      : keyPath[0] === "/technical"
      ? (window.location = "https://github.com/XR2Learn/.github/wiki")
      : navigate(keyPath[0]);
  };

  const items = [
    {
      key: "/logout",
      label: <Button onClick={() => props.onLogout()}>Logout</Button>,
    },
  ];

  const menu = [
    {
      label: "Catalogue",
      title: "Catalogue",
      danger: false,
      disabled: false,
      icon: <UnorderedListOutlined />,
      key: "/catalogue",
    },
    {
      label: "Marketplace",
      title: "Marketplace",
      danger: false,
      disabled: false,
      icon: <ShoppingCartOutlined />,
      key: "/marketplace",
    },
    {
      key: "/wishlist",
      label: "Wishlist",
      danger: false,
      disabled: false,
      icon: <HeartOutlined />,
    },
    {
      key: userStore.user && `/portfolio/${userStore.user.username}`,
      label: "Portfolio",
      danger: false,
      disabled: false,
      icon: <UserOutlined />,
    },
    {
      label: "Community",
      title: "Community",
      danger: false,
      disabled: false,
      icon: <UsergroupAddOutlined />,
      key: "/community",
    },
    {
      label: "Technical Wiki",
      title: "Technical Wiki",
      danger: false,
      disabled: false,
      icon: <BookOutlined />,
      key: "/technical",
    },
    // {
    //   label: "Moderation",
    //   title: "Moderation",
    //   danger: false,
    //   disabled: false,
    //   icon: <BookOutlined />,
    //   key: "/moderation",
    // },
  ];
  if (userStore.userRoleData) {
    if (
      userStore.userRoleData.type === "moderator" ||
      userStore.userRoleData.type === "admin"
    ) {
      menu.push({
        key: "/moderation",
        label: <span style={{ color: "white" }}>Moderation</span>,
        danger: false,
        disabled: false,
        // icon: <SettingOutlined style={{ color: "white" }} />,
      });
    }
  }

  useEffect(() => {
    userStore.getToken();
    // marketplaceStore.GetCart();
    userStore.GetUser();
  }, [userStore, marketplaceStore]);

  return (
    <Row
      align={"middle"}
      justify={"center"}
      style={{
        color: "#ffffff",
        padding: "10px",
        fontSize: "44px",
      }}
      className={
        window.location.pathname === "/" ? "home-page-color" : "header-color"
      }
    >
      <Col span={3} xl={3} xxl={3} md={3} sm={24} xs={24}>
        <Image
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          preview={false}
          src="xr2learn_logo.png"
        />
      </Col>

      <Col span={12} xl={12} xxl={12} md={12} sm={24} xs={24}>
        <Menu
          // theme={'dark'}
          mode="horizontal"
          items={menu}
          style={{
            background: "transparent",
            color: "white",
            fontSize: "16px",
            minWidth: 0,
            flex: "auto",
          }}
          onSelect={(e) => onMenuItemSelect(e)}
          onClick={(e) => onMenuItemSelect(e)}
        />
      </Col>
      <Col span={6} xl={3} xxl={3} md={3} sm={24} xs={24}>
        <Button
          style={{ backgroundColor: "#03183F", color: "white" }}
          onClick={handleConnect}
        >
          {marketplaceStore.metaMaskIsConnected
            ? "MetaMask is connected!"
            : "Connect your wallet."}
        </Button>
      </Col>
      <Col span={1} xxl={1} xl={12} lg={12} md={12} sm={24} xs={24}>
        {/* <Space size={"large"}>
          <CartDropdownMenu />
        </Space> */}
      </Col>
      <Col span={1} xxl={1} xl={12} lg={12} md={12} sm={24} xs={24}>
        <Dropdown menu={{ items }}>
          <Button type="link" onClick={(e) => e.preventDefault()}>
            <span style={{ fontSize: "16px", color: "white" }}>
              Welcome, {userStore.user && userStore.user.first_name}
            </span>{" "}
            <UserOutlined style={{ fontSize: "16px", color: "white" }} />
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default observer(MainHeader);
