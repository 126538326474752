import React, { useState } from "react";
import { Modal, Input, Button, Form, message } from "antd";
import { useMobxStores } from "../../stores/stores";

const ReportModal = ({ visible, onClose, itemId }) => {
  const { reportStore } = useMobxStores();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log(itemId);
    try {
      await reportStore.CreateReport(itemId, { reason: values.reason });
      message.success("Report submitted successfully");
      form.resetFields();
      onClose();
    } catch (error) {
      console.group(error);
      message.error("Failed to submit report");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Report Item"
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="reason"
          rules={[{ required: true, message: "Please provide a reason" }]}
        >
          <Input.TextArea rows={4} placeholder="Reason for reporting" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
          <Button onClick={onClose} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReportModal;
