import React, { useState, useEffect, useLayoutEffect } from "react";
import BinImage from "../assets/Delete.png";
import WarnImage from "../assets/Warn.png";
import IgnoreImage from "../assets/Ignore.png";
import BackgroundImage from "../assets/moderation-background.png";
import {
  Layout,
  Typography,
  Button,
  Space,
  Tooltip,
  Row,
  Col,
  Tag,
  Collapse,
  Card,
} from "antd";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useMobxStores } from "../stores/stores";

const { Title, Text, Link: AntLink } = Typography;
const { Panel } = Collapse;
const { Content } = Layout;

const Moderation = () => {
  const { reportStore, userStore, marketplaceStore } = useMobxStores();
  const [reports, setReports] = useState([]);
  const [usernames, setUsernames] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchItems() {
      await marketplaceStore.GetMarketPlaceItems();
    }
    fetchItems();
  }, [marketplaceStore]);

  useEffect(() => {
    async function fetchReports() {
      await reportStore.GetReports();
    }
    fetchReports();
  }, [reportStore]);

  useEffect(() => {
    const fetchUsernames = async () => {
      const newUsernames = {};
      for (const report of reportStore.reports) {
        if (!usernames[report.id]) {
          await userStore.GetUserById(report.reporter);
          newUsernames[report.id] = userStore.reportedUser.username;
        }
      }
      setUsernames((prevUsernames) => ({ ...prevUsernames, ...newUsernames }));
    };

    if (reportStore.reports.length > 0) {
      fetchUsernames();
    }
  }, [reportStore.reports, userStore]);

  useEffect(() => {
    if (Object.keys(usernames).length > 0) {
      const formattedReports = reportStore.reports.reduce(
        (acc, report, index) => {
          const existingItem = acc.find(
            (item) => item.item === `Marketplace-item #${report.item}`
          );
          const reportData = {
            reason: report.reason,
            reporter: usernames[report.id] || `User ${report.reporter}`,
            received: new Date(report.report_received).toLocaleString(),
            status: report.action,
          };

          if (existingItem) {
            existingItem.reports.push(reportData);
          } else {
            fetchItem(report.item);
            acc.push({
              key: index.toString(),
              id: report.id,
              item: marketplaceStore.marketPlaceItem?.name,
              // `Marketplace-item #${report.item}`,
              reports: [reportData],
            });
          }

          return acc;
        },
        []
      );
      setReports(formattedReports);
    }
    async function fetchItem(id) {
      await marketplaceStore.GetMarketPlaceItemById(id);
    }
  }, [usernames, reportStore.reports, marketplaceStore.marketPlaceItems]);

  const handleAction = async (action, itemIndex, reportIndex) => {
    const reportId = reports[itemIndex].id;
    try {
      await reportStore.UpdateReportById(reportId, { action });
      const newReports = [...reports];
      newReports[itemIndex].reports[reportIndex].status = action;
      setReports(newReports);
    } catch (error) {
      console.error("Error updating report:", error);
    }
  };

  const renderActions = (itemIndex, reportIndex, status) => (
    <Space>
      {status !== "warned" && status !== "removed" && (
        <>
          <Tooltip title="Warn">
            <Button
              shape="circle"
              onClick={() => handleAction("warned", itemIndex, reportIndex)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                width: "auto",
                height: "auto",
              }}
              icon={
                <img
                  src={WarnImage}
                  alt="Warn"
                  style={{
                    width: "35px",
                    height: "35px",
                    display: "block",
                  }}
                />
              }
            />
          </Tooltip>

          <Tooltip title="Ignore">
            <Button
              shape="circle"
              onClick={() => handleAction("ignored", itemIndex, reportIndex)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                width: "auto",
                height: "auto",
              }}
              icon={
                <img
                  src={IgnoreImage}
                  alt="Ignore"
                  style={{
                    width: "35px",
                    height: "35px",
                    display: "block",
                  }}
                />
              }
            />
          </Tooltip>
        </>
      )}
      {status === "warned" && (
        <>
          {" "}
          <Tooltip title="Remove">
            <Button
              shape="circle"
              onClick={() => handleAction("removed", itemIndex, reportIndex)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                width: "auto",
                height: "auto",
              }}
              icon={
                <img
                  src={BinImage}
                  alt="Remove"
                  style={{
                    width: "35px",
                    height: "35px",
                    display: "block",
                  }}
                />
              }
            />
          </Tooltip>
          <Tooltip title="Ignore">
            <Button
              shape="circle"
              onClick={() => handleAction("ignored", itemIndex, reportIndex)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                padding: 0,
                marginLeft: "1em",
                width: "auto",
                height: "auto",
              }}
              icon={
                <img
                  src={IgnoreImage}
                  alt="Ignore"
                  style={{
                    width: "35px",
                    height: "35px",
                    display: "block",
                  }}
                />
              }
            />
          </Tooltip>
        </>
      )}
    </Space>
  );

  const statusStyle = {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    border: 0,
    fontWeight: 600,
    background: "linear-gradient(91.54deg, #D01FDF 7.3%, #755FD8 45.53%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  };

  const [activeKey, setActiveKey] = useState(null);

  const handleChange = (key) => {
    setActiveKey(key);
  };

  const getPanelStyle = (key) => {
    return activeKey === key
      ? {
          background: "rgba(0,0,0,0)",
          border: "none",
          borderTop: "2px solid",
          borderImage: "linear-gradient(90deg, #D01FDF 0%, #2A93D3 100%) 1",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }
      : {
          background: "rgba(0,0,0,0)",
          border: "none",
          borderBottom: "2px solid",
          borderImage: "linear-gradient(90deg, #D01FDF 0%, #2A93D3 100%) 1",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        };
  };

  const headerStyle = {
    fontFamily: "Work Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "29px",
    textAlign: "right",
    color: "#FFFFFF",
  };

  return (
    <Layout
      style={{
        marginLeft: "-5px",
        minHeight: "100vh",
        backgroundImage: `url(${BackgroundImage})`, // Use the imported image here
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundPosition: "left", // Centers the image
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
      }}
    >
      <Title
        style={{
          fontFamily: "Work Sans",
          fontStyle: "normal",
          fontWeight: 500,
          color: " #FFFFFF",
          marginLeft: "-17em",
          fontSize: "50px",
          textAlign: "center",
        }}
      >
        Moderation System
      </Title>

      <Content
        style={{
          paddingLeft: "300px",
          paddingRight: "300px",
          paddingTop: "24px",
          paddingBottom: "24px",
        }}
      >
        <Card
          style={{
            background: "rgba(255, 255, 255, 0)", // Adjust alpha for semi-transparency
            border: "1px solid rgba(255, 255, 255, 0.5)", // Semi-transparent border
            padding: "0px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", // Optional shadow
            borderRadius: "15px",
            backdropFilter: "blur(10px)", // Optional glass-like blur effect
          }}
          className="custom-card"
        >
          <Title
            level={2}
            style={{
              color: "rgb(255,255,255)",
              backgroundColor: "#D01FDF",
              padding: "8px",
              borderRadius: "10px",
              margin: "-1px",
              paddingLeft: "15px",
            }}
          >
            Reported Items
          </Title>
          <Collapse
            accordion
            onChange={handleChange}
            style={{ border: "none", marginBottom: "10em" }}
          >
            {reports.map((item, itemIndex) => (
              <Panel
                header={<span style={headerStyle}>{item.item}</span>}
                key={item.key}
                style={getPanelStyle(item.key)} // Dynamically apply styles
                showArrow={false} // Hide the arrow
              >
                <Row
                  gutter={16}
                  style={{
                    background: " rgba(250, 246, 246, 0)",
                    marginTop: "-2em",
                  }}
                >
                  <Col span={4}>
                    <Title level={4}>Reason</Title>
                  </Col>
                  <Col span={6}>
                    <Title level={4}>Reporter</Title>
                  </Col>
                  <Col span={4}>
                    <Title level={4}>Received</Title>
                  </Col>
                  <Col span={4}>
                    <Title level={4}>Status</Title>
                  </Col>
                  <Col span={5}>
                    <Title level={4}>Action</Title>
                  </Col>
                </Row>
                {item.reports.map((report, reportIndex) => (
                  <Row
                    gutter={16}
                    key={`${item.key}-${reportIndex}`}
                    style={{
                      backgroundColor: "rgba(0,0,0,0) ",
                    }}
                  >
                    <Col span={4}>
                      <Text
                        style={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          color: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        {report.reason}
                      </Text>
                    </Col>
                    <Col span={6}>
                      <AntLink
                        style={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 400,
                          textDecorationLine: "underline",

                          color: "#D01FDF",
                        }}
                        onClick={() =>
                          navigate(`/portfolio/${report.reporter}`)
                        }
                      >
                        {report.reporter}
                      </AntLink>
                    </Col>
                    <Col span={4}>
                      <Text
                        style={{
                          fontFamily: "Work Sans",
                          fontStyle: "normal",
                          fontWeight: 300,
                          color: "rgba(255, 255, 255, 0.8)",
                        }}
                      >
                        {report.received}
                      </Text>
                    </Col>
                    <Col span={4}>
                      <Text color="default" style={statusStyle}>
                        {report.status}
                      </Text>
                    </Col>

                    <Col span={5}>
                      {renderActions(itemIndex, reportIndex, report.status)}
                    </Col>
                  </Row>
                ))}
              </Panel>
            ))}
          </Collapse>
        </Card>
      </Content>
    </Layout>
  );
};

export default observer(Moderation);
