import React from "react";
import ErrorStore from "./ErrorStore";
import MainStore from "./MainStore";
import UserStore from "./UserStore";
import StyleStore from "./StyleStore";
import MarketPlaceStore from "./MarketPlaceStore";
import CatalogueStore from "./CatalogueStore";
import ReportStore from "./reportStore";

const isServer = typeof window === "undefined";
let clientSideStores;

export function getStores() {
  var errorStore = new ErrorStore();
  if (isServer) {
    return {
      errorStore: errorStore,
      mainStore: new MainStore({ errStore: errorStore }),
    };
  }
  if (!clientSideStores) {
    clientSideStores = {
      errorStore: errorStore,
      mainStore: new MainStore({ errStore: errorStore }),
      userStore: new UserStore({ errStore: errorStore }),
      styleStore: new StyleStore({ errStore: errorStore }),
      catalogueStore: new CatalogueStore({ errStore: errorStore }),
      marketplaceStore: new MarketPlaceStore({ errStore: errorStore }),
      reportStore: new ReportStore({ errStore: errorStore }),
    };
  }

  return clientSideStores;
}

const StoreContext = React.createContext();

export function StoreProvider(props) {
  return (
    <StoreContext.Provider value={props.value}>
      {props.children}
    </StoreContext.Provider>
  );
}

export function useMobxStores() {
  return React.useContext(StoreContext);
}
