import { makeAutoObservable } from "mobx";
import { GET, PUT, PATCH, POST } from "../apis/apis";

class ReportStore {
  constructor({ errStore }) {
    makeAutoObservable(this);
    this.errStore = errStore;
  }

  reports = [];
  reportDetails = null;
  isReportModalOpen = false;

  setReports = (value) => {
    this.reports = value;
  };

  setReportDetails = (value) => {
    this.reportDetails = value;
  };

  setIsReportModalOpen = (value) => {
    this.isReportModalOpen = value;
  };

  setToken = (value) => {
    this.token = value;
  };

  getToken = () => {
    this.token = JSON.parse(localStorage.getItem("dafne-user-data")).token;
  };

  // Get all reports
  GetReports = () => {
    this.errStore.setLoading(true);
    GET(`/report`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setReports(data);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // Get report by ID
  GetReportById = (id) => {
    this.errStore.setLoading(true);
    GET(`/report/${id}`)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setReportDetails(data);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // Update report by ID (PUT)
  UpdateReportById = (id, values) => {
    this.errStore.setLoading(true);
    console.log(values);
    PUT(`/report/${id}`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setReportDetails(data);
        this.setIsReportModalOpen(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // Patch report by ID
  PatchReportById = (id, values) => {
    this.errStore.setLoading(true);
    PATCH(`/report/${id}`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.setReportDetails(data);
        this.setIsReportModalOpen(false);
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };

  // Create new report
  CreateReport = (item, values) => {
    this.errStore.setLoading(true);
    POST(`/report/create/${item}`, values)
      .then((data) => {
        this.errStore.setMessage("");
        this.errStore.setLoading(false);
        this.GetReports(); // Refresh the list of reports
      })
      .catch((error) => {
        this.errStore.handlerErrors(error);
      });
  };
}

export default ReportStore;
